import React, {Fragment} from 'react';

const NotFound = () => {
    return (
        <Fragment>
            <h1>Not Found</h1>
            <h3>404</h3>
        </Fragment>
    )
}

export default NotFound;